import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {EnvironmentInterface} from '../../environments/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements EnvironmentInterface {
  constructor() {
  }

  get production(): boolean {
    return environment.production;
  }

  get API_BASE_URL(): string {
    return environment.API_BASE_URL;
  }
}
